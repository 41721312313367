<script setup lang="ts" generic="T">
import { computed } from 'vue'
import { useField } from 'vee-validate'
import { type FormFieldProps, TextField } from '.'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField<string>(name.value)
</script>

<template>
  <TextField :field :disabled>
    <template #input="{ id }">
      <textarea
        :id
        v-bind="field.attrs"
        v-model="value"
        :name
        :form
        :disabled
      ></textarea>
    </template>

    <template v-if="errorMessage" #error>{{ errorMessage }}</template>
  </TextField>
</template>
