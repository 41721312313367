import { ComputedRef, Plugin, Ref, computed, inject, readonly, ref } from 'vue'
import axios from 'axios'
import { jwtDecode } from 'jwt-decode'
import { useTimeoutPoll } from '@vueuse/core'

export interface AuthOptions {
  baseUrl: string
  loginUrl: string
  logoutUrl: string
  refreshUrl: string
  profileUrl: string
  pollPaddingPercent: number
}

export interface AuthInstance {
  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
  refresh: () => Promise<void>
  currentUser: () => Promise<void>
  isLoggedIn: ComputedRef<boolean>
  tokenBody: ComputedRef<Record<string, unknown> | null>
  isReady: Promise<void>
  accessToken: Readonly<Ref<string | null>>
  lastRefresh: Readonly<Ref<number | null>>
  isPolling: Readonly<Ref<boolean>>
}

export const key = Symbol('auth')

let instance: ReturnType<typeof auth> | null = null

// TODO: make option?
const MIN_POLL_MS = 60_000 // 60 seconds

export const getAuth = () => {
  if (!instance) {
    throw new Error('getAuth must be called after the auth plugin is installed')
  }
  return instance
}

// TODO: transforms for each call so custom requests can be made

function auth(options: AuthOptions) {
  const pollIntervalMs = ref<number>(MIN_POLL_MS) // 5 minutes

  const { isActive, pause, resume } = useTimeoutPoll(
    async () => {
      return refresh()
    },
    pollIntervalMs,
    { immediate: false }
  )

  const accessToken = ref<string | null>(null)

  const lastRefresh = ref<number | null>(null)

  const tokenBody = computed(() => {
    return accessToken.value ? jwtDecode(accessToken.value) : null
  })

  const client = axios.create({
    baseURL: options.baseUrl,
    withCredentials: true,
  })

  const setupPoll = () => {
    if (tokenBody.value) {
      // both exp and iat are in seconds
      const exp = tokenBody.value.exp ?? 0
      const iat = tokenBody.value.iat ?? 0
      const diffSec = exp - iat

      if (diffSec > 0) {
        const paddingSec = diffSec * options.pollPaddingPercent
        pollIntervalMs.value = Math.max(
          Math.floor(diffSec - paddingSec) * 1000,
          MIN_POLL_MS
        )

        console.log('pollIntervalMs', pollIntervalMs.value)

        resume()
      }
    }
  }

  const login = async (email: string, password: string) => {
    const res = await client.post(
      options.loginUrl,
      { email, password },
      { withCredentials: true }
    )
    accessToken.value = res.data.access_token

    fetchCurrentUser()
    setupPoll()
  }

  const reset = () => {
    accessToken.value = null
    lastRefresh.value = null
    currentUser.value = null
    pause()
  }

  const logout = async () => {
    await client.post(options.logoutUrl)
    reset()
  }

  const refresh = async () => {
    try {
      const res = await client.post(options.refreshUrl)
      lastRefresh.value = Date.now()
      accessToken.value = res.data.access_token

      setupPoll()
    } catch (e) {
      // there was no refresh token
      reset()
    }
  }

  const isLoggedIn = computed(() => accessToken.value !== null)

  const currentUser = ref<Record<string, unknown> | null>(null)

  const fetchCurrentUser = async () => {
    if (!isLoggedIn.value) return null
    if (!tokenBody.value?.sub) return null

    // const id = tokenBody.value.sub as string

    // await client.get(options.profileUrl.replace(':id', id), {
    //   headers: { Authorization: `Bearer ${accessToken.value}` },
    // })
  }

  const isReady = refresh()

  return {
    login,
    logout,
    refresh,
    isLoggedIn,
    tokenBody,
    isReady,
    accessToken: readonly(accessToken),
    lastRefresh: readonly(lastRefresh),
    currentUser: readonly(currentUser),
    isPolling: isActive,
  }
}

export const createAuth = (): Plugin => ({
  install(app, options: Partial<AuthOptions>) {
    const localOptions: AuthOptions = {
      baseUrl: '/',
      loginUrl: '/login',
      logoutUrl: '/logout',
      refreshUrl: '/refresh',
      profileUrl: '/profile/:id',
      pollPaddingPercent: 0.01, // 1%
      ...options,
    }

    instance = auth(localOptions)

    app.provide(key, instance)
  },
})

export function useAuth() {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return inject<ReturnType<typeof auth>>(key)!
}
