<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router'
import { DForm, Logo, PromptPanel } from '@/components'
import { useAuth } from '@/plugins/auth'
import { loginFormSchema } from '@/forms'
import { Login } from '@@/types'

const router = useRouter()
const route = useRoute()

const { login: authLogin } = useAuth()

const login = async (data: Partial<Login>) => {
  if (!data.email || !data.password) return
  await authLogin(data.email, data.password)

  // if supplied, redirect to the previous page after login
  const redirect = route.query.redirect
  if (redirect) {
    router.replace(redirect as string)
  } else {
    router.replace({ name: 'home' })
  }
}
</script>

<template>
  <UseHead>
    <title>{{ $t('pages.login.title') }}</title>
  </UseHead>

  <PromptPanel class="w-[min(18rem,90vw)]">
    <template #header>
      <div class="flex aspect-square w-full items-center justify-center">
        <Logo size="xl" />
      </div>

      <p class="text-lavender-800">Welcome back!</p>
    </template>

    <DForm
      id="login"
      :schema="loginFormSchema"
      submit-text="Sign In"
      cancel-text=""
      @submit="login"
    />
  </PromptPanel>
</template>
