<script setup lang="ts" generic="T">
import { computed, ref } from 'vue'
import { useField } from 'vee-validate'
import { PhCaretUpDown } from '@phosphor-icons/vue'
import { type FormFieldProps, TextField } from '.'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField<string>(name.value)

const input = ref()

const hasOptions = computed(
  () => props.field.options && props.field.options.length > 0
)

const isDisabled = computed(() => props.disabled || !hasOptions.value)
</script>

<template>
  <TextField
    :field
    :disabled="isDisabled"
    @click.prevent="!isDisabled && input.showPicker()"
  >
    <template #input="{ id }">
      <select
        :id
        ref="input"
        v-model="value"
        :name
        :form
        :disabled="isDisabled"
        v-bind="field.attrs"
      >
        <option v-if="hasOptions" :value="null" hidden disabled>
          {{
            field.i18n && field.placeholder
              ? $t(field.placeholder)
              : field.placeholder ?? $t('forms.fallback.selectPlaceholder')
          }}
        </option>

        <option v-else :value="null" hidden disabled>
          {{ $t('forms.fallback.selectNoOptionsPlaceholder') }}
        </option>

        <option
          v-for="option in field.options"
          :key="option.value"
          :value="option.value"
          :disabled="option.disabled"
        >
          {{ field.i18nOptions ? $t(option.label) : option.label }}
        </option>
      </select>
    </template>

    <template #button>
      <div
        class="flex grow items-center justify-center rounded-r-lg outline-none elevation-0 thickness-lavender-400/20"
        :class="[
          isDisabled
            ? 'bg-lavender-100 pb-0 text-lavender-300 thickness-1'
            : 'bg-lavender-50 pb-0.5 text-lavender-500 thickness-3',
        ]"
      >
        <PhCaretUpDown weight="bold" size="1.25em" />
      </div>
    </template>

    <template v-if="errorMessage" #error>{{ errorMessage }}</template>
  </TextField>
</template>
