import { ref } from 'vue'

export function useUniqueId(prefix?: string) {
  const uid = ref()

  uid.value =
    (prefix ? `${prefix}_` : '') +
    String(Math.random().toString(36).slice(2, 9))

  return uid
}
