<script setup lang="ts" generic="T">
import { computed, onMounted, ref, watch } from 'vue'
import { useFocus } from '@vueuse/core'
import { useField } from 'vee-validate'
import { useI18n } from 'vue-i18n'
import { type FormFieldProps, useUniqueId } from '.'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField(name.value)

const id = useUniqueId(name.value)

const root = ref()
const { focused: focusedRoot } = useFocus(root)
const inputWrapper = ref()
const input = ref()
const { focused } = useFocus(input)

watch(focusedRoot, () => {
  if (!props.disabled && focusedRoot) {
    focused.value = true
  }
})

const { t } = useI18n()
const placeholder = computed(() =>
  props.field.placeholder && props.field.i18n
    ? t(props.field.placeholder)
    : props.field.placeholder
)

const isEmpty = computed(
  () => value.value === undefined || value.value === null || value.value === ''
)

onMounted(() => {
  input.value = inputWrapper.value.querySelector('input, select, textarea')
})
</script>

<template>
  <div class="flex flex-col gap-1.5 text-left text-lavender-800">
    <label v-if="!field.compact" :for="id" class="px-3 font-bold">
      {{ field.i18n ? $t(field.label) : field.label }}
    </label>

    <div
      ref="root"
      tabindex="-1"
      :disabled
      class="relative flex min-h-10 items-stretch overflow-hidden rounded-lg outline-none elevation-lavender-400/20 thickness-lavender-400/20"
      :class="[
        disabled
          ? 'bg-lavender-100 elevation-0 thickness-1'
          : 'bg-milk elevation-1 thickness-3 focus-within:-translate-y-0.5 focus-within:elevation-2 focus-within:elevation-lavender-400/40',
      ]"
    >
      <!-- Icon -->
      <div
        v-if="field.icon"
        class="flex shrink-0 items-center justify-center border-r border-lavender-200 px-3 *-[svg]:size-[1.25em]"
        :class="[disabled ? 'pb-0' : 'pb-0.5']"
      >
        <component :is="field.icon" weight="duotone" />
      </div>

      <div
        class="flex min-w-0 grow flex-col justify-center px-3 pt-2"
        :class="[disabled ? 'pb-2' : 'pb-2.5', { 'pl-2': field.icon }]"
      >
        <!-- Builtin Label -->
        <label v-if="field.compact" :for="id" class="text-b4">
          {{ field.i18n ? $t(field.label) : field.label }}
        </label>

        <!-- Input -->
        <div ref="inputWrapper" :class="{ 'text-muted': isEmpty }">
          <slot name="input" v-bind="{ id, placeholder }">
            <input
              :id
              v-model="value"
              type="text"
              :name
              :placeholder
              :form
              :disabled
              v-bind="field.attrs"
            />
          </slot>
        </div>
      </div>

      <!-- Button -->
      <div
        v-if="$slots.button"
        class="flex w-8 flex-col items-stretch justify-center"
      >
        <slot name="button" />
      </div>
    </div>

    <div
      v-if="errorMessage || $slots.error"
      class="text-b3 px-3 text-tomato-500"
    >
      <slot name="error">{{ errorMessage }}</slot>
    </div>

    <div v-else-if="field.help" class="text-muted text-b3 px-3">
      {{ field.i18n ? $t(field.help) : field.help }}
    </div>
  </div>
</template>
