<script setup lang="ts">
import { ref, computed, useSlots, watch } from 'vue'
import { vOnClickOutside } from '@vueuse/components'
import { PhCaretDown } from '@phosphor-icons/vue'
import { Loading } from '@/components/Loading'
import { ButtonProps } from '.'

const props = withDefaults(defineProps<ButtonProps>(), {
  volume: 'quiet',
  size: 'md',
})

defineOptions({
  inheritAttrs: false,
})

const isDisabled = computed(() => props.disabled || props.loading)

const { menu } = useSlots()

const menuIsOpen = ref(false)

const toggleMenu = (value: boolean | undefined) => {
  if (!menu) return

  menuIsOpen.value = typeof value === 'boolean' ? value : !menuIsOpen.value
}

watch(isDisabled, () => isDisabled.value && toggleMenu(false))
</script>

<template>
  <span
    class="relative"
    :class="{
      'text-b2': size === 'md',
      'text-b3': size === 'sm',
    }"
  >
    <component
      :is="
        disabled && ($attrs.to || $attrs.href)
          ? 'span'
          : $attrs.to
            ? 'RouterLink'
            : $attrs.href
              ? 'a'
              : 'button'
      "
      :disabled="isDisabled"
      v-bind="$attrs"
      class="flex items-stretch justify-center overflow-hidden rounded-full !no-underline outline-2 outline-offset-4 hover:-translate-y-0.5 active:translate-y-0.5"
      :class="{
        'h-10 thickness-3': size === 'md',
        'h-8 thickness-2': size === 'sm',
        'w-10': iconOnly && size === 'md',
        'w-8': iconOnly && size === 'sm',

        'elevation-1 hover:elevation-2 active:elevation-1': !isDisabled,
        'pointer-events-none select-none elevation-0 !thickness-1': isDisabled,

        '-translate-y-0.5 elevation-2': menuIsOpen,

        // Quiet
        'bg-lavender-200 text-lavender-600 outline-lavender-400 elevation-lavender-400/40 thickness-lavender-300 hover:text-lavender-700 focus:text-lavender-700':
          volume === 'quiet' && !destructive && !isDisabled,

        // Quiet + Disabled
        'bg-lavender-100 text-lavender-300 thickness-lavender-200':
          volume === 'quiet' && !destructive && isDisabled,

        // Quiet + Destructive
        'bg-tomato-200 text-tomato-500 outline-tomato-400 elevation-tomato-400/40 thickness-tomato-300 hover:text-tomato-600 focus:text-tomato-600':
          volume === 'quiet' && destructive && !isDisabled,

        // Loud
        'bg-eggplant-700 text-eggplant-200 outline-eggplant-700 elevation-eggplant-800/40 thickness-eggplant-800 hover:text-eggplant-50 focus:text-eggplant-50':
          volume === 'loud' && !destructive && !isDisabled,

        // Loud + Disabled
        'bg-eggplant-100 text-eggplant-300 thickness-eggplant-200':
          volume === 'loud' && !destructive && isDisabled,

        // Loud + Destructive
        'bg-tomato-600 text-tomato-200 outline-tomato-600 elevation-tomato-800/40 thickness-tomato-800 hover:text-tomato-50 focus:text-tomato-50':
          volume === 'loud' && destructive && !isDisabled,

        // Destructive + Disabled
        'bg-tomato-100 text-tomato-300 thickness-tomato-200':
          destructive && isDisabled,
      }"
      @click.stop="toggleMenu"
    >
      <span
        class="relative flex grow items-center justify-center"
        :class="[isDisabled ? 'pb-0' : 'pb-0.5']"
      >
        <!-- Loader -->
        <span
          v-if="loading"
          class="absolute inset-0 flex items-center justify-center px-4"
          :class="{ invisible: !loading }"
        >
          <Loading />
        </span>

        <!-- Content -->
        <span
          class="flex items-center justify-center truncate *-[svg]:size-[1.25em] *:shrink-0"
          :class="{
            invisible: loading,
            'gap-4 px-6 *:-mx-2': size === 'md',
            'gap-2.5 px-4 *:-mx-1': size === 'sm',
          }"
        >
          <slot />
        </span>
      </span>

      <!-- Caret -->
      <span
        v-if="!iconOnly && $slots.menu"
        class="-ml-1 flex items-center justify-center border-l"
        :class="[
          isDisabled ? 'pb-0' : 'pb-0.5',
          {
            'pl-2.5 pr-3': size === 'md',
            'pl-2 pr-2.5': size === 'sm',

            // Quiet
            'border-lavender-400/40 bg-lavender-300/40':
              volume === 'quiet' && !destructive && !isDisabled && !menuIsOpen,

            // Quiet + Disabled
            'border-lavender-300/40 bg-lavender-200/40':
              volume === 'quiet' && !destructive && isDisabled,

            // Quiet + Menu is Open
            'border-lavender-400/80 bg-lavender-300/80':
              volume === 'quiet' && !destructive && !isDisabled && menuIsOpen,

            // Quiet + Destructive
            'border-tomato-400/40 bg-tomato-300/40':
              volume === 'quiet' && destructive && !isDisabled && !menuIsOpen,

            // Quiet + Destructive + Menu is Open
            'border-tomato-400/80 bg-tomato-300/80':
              volume === 'quiet' && destructive && !isDisabled && menuIsOpen,

            // Loud
            'border-eggplant-950/40 bg-eggplant-900/40':
              volume === 'loud' && !destructive && !isDisabled && !menuIsOpen,

            // Loud + Disabled
            'border-eggplant-300/40 bg-eggplant-200/40':
              volume === 'loud' && !destructive && isDisabled,

            // Loud + Menu is Open
            'border-eggplant-950/80 bg-eggplant-900/80':
              volume === 'loud' && !destructive && !isDisabled && menuIsOpen,

            // Loud + Destructive
            'border-tomato-950/40 bg-tomato-900/40':
              volume === 'loud' && destructive && !isDisabled && !menuIsOpen,

            // Loud + Destructive + Menu is Open
            'border-tomato-950/80 bg-tomato-900/80':
              volume === 'loud' && destructive && !isDisabled && menuIsOpen,

            // Destructive + Disabled
            'border-tomato-300/40 bg-tomato-200/40':
              volume === 'loud' && destructive && isDisabled,
          },
        ]"
      >
        <PhCaretDown weight="bold" />
      </span>
    </component>

    <!-- Menu -->
    <Transition
      enter-active-class="transition-[opacity,transform] duration-200 ease-in-out-cubic origin-top-right"
      enter-from-class="opacity-0 rotate-x-90"
      enter-to-class="opacity-1 rotate-x-0"
      leave-active-class="transition-[opacity,transform] duration-200 ease-out-cubic origin-top-right"
      leave-from-class="opacity-1 rotate-x-0"
      leave-to-class="opacity-0 rotate-x-90"
    >
      <menu
        v-if="$slots.menu"
        v-show="menuIsOpen"
        v-on-click-outside.bubble="() => toggleMenu(false)"
        class="absolute right-0 mt-1 flex w-full min-w-48 flex-col divide-y divide-lavender-100 rounded-xl bg-milk p-1.5 elevation-3 elevation-lavender-400/20 *:shrink-0"
        @click="() => toggleMenu(false)"
      >
        <slot name="menu" />
      </menu>
    </Transition>
  </span>
</template>
