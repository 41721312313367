<script setup lang="ts">
import { computed } from 'vue'
import { Button } from '@/components'
import { FormAction } from '.'

const props = defineProps<{
  formId?: string
  actions?: FormAction[]
  loading?: boolean
  disabled?: boolean
  preventSubmit?: boolean
  i18n?: boolean
}>()

const visibleActions = computed(() => {
  return props.actions?.filter((action) => !action.hidden) ?? []
})
</script>

<template>
  <div class="grid grow auto-cols-fr grid-flow-col gap-3">
    <Button
      v-for="action in visibleActions"
      :key="action.id"
      :form="formId"
      :type="action.type"
      :volume="action.volume"
      :size="action.size"
      :loading="action.loading || loading"
      v-bind="action.attrs"
      class="w-full"
      @click="action.onClick"
    >
      <component :is="action.icon" v-if="action.icon" weight="duotone" />
      {{ i18n ? $t(action.label) : action.label }}
    </Button>
  </div>
</template>
