import * as Yup from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { FormSchema, PasswordField } from '@/components'
import { Login } from '@@/types'

export const loginFormSchema: FormSchema<Login> = {
  rules: toTypedSchema(
    Yup.object({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    })
  ),

  fields: [
    {
      name: 'email',
      label: 'forms.login.email',
      compact: true,
      i18n: true,
    },
    {
      name: 'password',
      label: 'forms.login.password',
      as: PasswordField,
      compact: true,
      i18n: true,
    },
  ],
}
