import { createApp } from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from '@/router'
import { createPinia } from 'pinia'
import { Head, createHead } from '@vueuse/head'
import * as Sentry from '@sentry/vue'
import detectHiddenScrollbar from '@/plugins/detectHiddenScrollbar'
import { createToaster } from '@/plugins/toaster'
import { PageHeader, PageLayout } from '@/components'
import { createAuth, getAuth } from '@/plugins/auth'
import { ApiOptions, createApi } from '@/plugins/api'
import '@/assets/index.css'

const app = createApp(App)

app.use(router)

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      // Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      /^https:\/\/match\.talint\.io\/api/,
      /^https:\/\/talint-match-alpha\.pages\.dev\/api/,
    ],

    // Capture Replay for 10% of all sessions,
    // replaysSessionSampleRate: 0.1,
    // plus for 100% of sessions with an error
    // replaysOnErrorSampleRate: 1.0,
  })
  // allows filtering/grouping by service
  Sentry.setTag('service', 'frontend')
}

app.use(createAuth(), {
  baseUrl: import.meta.env.VITE_API_BASE_URL + '/auth',
})
app.use<ApiOptions>(createApi(), {
  defaults: {
    baseURL: import.meta.env.VITE_API_BASE_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  },
  interceptors: {
    request: [
      async (config) => {
        const auth = getAuth()

        if (auth) {
          await auth.isReady
          if (auth.accessToken.value) {
            config.headers.Authorization = `Bearer ${auth.accessToken.value}`
          }
        }

        return config
      },
    ],
  },
})
app.use(i18n)
app.use(createPinia())
app.use(
  createHead({
    titleTemplate: (title) => (title ? `${title} - ` : '') + 'Talint Match',
  })
)
app.use(detectHiddenScrollbar)
app.use(createToaster(), { variant: 'primary' })

app.component('UseHead', Head)
app.component('PageLayout', PageLayout)
app.component('PageHeader', PageHeader)

app.mount('#app')
