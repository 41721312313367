<script setup lang="ts" generic="T">
import { onMounted, ref, computed } from 'vue'
import { useField } from 'vee-validate'
import { type FormFieldProps, TextField } from '.'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField<number>(name.value)

const input = ref()

const min = computed(() =>
  input.value && input.value.min.length > 0 ? input.value.min : 0
)
const max = computed(() =>
  input.value && input.value.max.length > 0 ? input.value.max : 100
)
const progress = computed(() =>
  Math.round(((value.value ?? min.value) / (max.value - min.value)) * 100)
)

onMounted(() => {
  if (value.value === undefined) value.value = min.value
})
</script>

<template>
  <TextField :field :disabled>
    <template #input="{ id }">
      <div class="flex items-center gap-3">
        <input
          :id
          ref="input"
          v-model="value"
          type="range"
          :name
          :form
          :disabled
          v-bind="field.attrs"
          class="range-thumb:mt-px range-thumb:size-6 range-thumb:cursor-ew-resize range-thumb:appearance-none range-thumb:rounded-full range-thumb:border-none range-thumb:elevation-1 range-thumb:thickness-2 range-thumb-wk:-translate-y-1/2 range-track:h-1 range-track:cursor-pointer range-track:appearance-none range-track:rounded-sm range-track:bg-lavender-100 range-track:bg-gradient-to-r range-track:from-eggplant-600 range-track:to-eggplant-700 range-track:bg-[length:var(--progress)] range-track:bg-no-repeat"
          :class="[
            progress === 0
              ? 'range-thumb:elevation-laveneder-400/40 range-thumb:bg-lavender-200 range-thumb:thickness-lavender-300'
              : 'range-thumb:eggplant-800/40 range-thumb:bg-eggplant-600 range-thumb:thickness-eggplant-800',
          ]"
          :style="{ '--progress': progress + '%' }"
        />

        <span class="text-num w-10 text-center font-semibold text-lavender-700">
          {{ progress }}%
        </span>
      </div>
    </template>

    <template v-if="errorMessage" #error>{{ errorMessage }}</template>
  </TextField>
</template>
