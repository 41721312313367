<script setup lang="ts" generic="T">
import { computed, ref } from 'vue'
import { useField } from 'vee-validate'
import * as pdfjs from 'pdfjs-dist'
import { PhFileArrowUp } from '@phosphor-icons/vue'
import { type FormFieldProps } from '.'
import TextField from './TextField.vue'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField<string>(name.value)

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

defineOptions({
  inheritAttrs: false,
})

const loading = ref(false)

const parseFile = async (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]

  if (!file) return

  loading.value = true

  const reader = new FileReader()

  reader.onload = async (event) => {
    const buffer = event.target?.result as ArrayBuffer
    const pdf = await pdfjs.getDocument(buffer).promise
    console.log('page count', pdf.numPages)

    let text = ''

    for (let i = 1; i <= pdf.numPages; i++) {
      const page = await pdf.getPage(i)
      const textTokens = await page.getTextContent()
      text += textTokens.items
        .map((token) => {
          if (!('str' in token)) return ''

          return token.str.length ? token.str : '\n'
        })
        .join('')
    }

    console.log(text)

    value.value = text

    loading.value = false
  }

  reader.readAsArrayBuffer(file)
}
</script>

<template>
  <TextField :field :disabled>
    <template #input="{ id }">
      <div class="flex flex-col gap-2">
        <textarea
          :id
          v-bind="field.attrs"
          v-model="value"
          :name
          :form
          :disabled
        ></textarea>

        <label
          class="text-b3 inline-flex w-full cursor-pointer items-center gap-1 self-start border-t border-lavender-400/20 pb-0.5 pt-2.5 text-lavender-800/60 focus-within:text-lavender-800 hover:text-lavender-800"
        >
          <PhFileArrowUp weight="duotone" size="1.25em" />
          {{ $t('components.textFromFileField.insertTextAction') }}
          <input
            type="file"
            :disabled="disabled"
            accept=".pdf"
            class="sr-only"
            @change="parseFile($event)"
          />
        </label>
      </div>
    </template>

    <template v-if="errorMessage" #error>{{ errorMessage }}</template>
  </TextField>
</template>
