<script setup lang="ts" generic="T">
import { computed, ref } from 'vue'
import { useField } from 'vee-validate'
import { PhEye, PhEyeClosed } from '@phosphor-icons/vue'
import { type FormFieldProps, TextField } from '.'

const props = defineProps<FormFieldProps<T>>()

const name = computed(() => String(props.field.name))

const { value, errorMessage } = useField<string>(name.value)

const showPassword = ref(false)
</script>

<template>
  <TextField :field :disabled>
    <template #input="{ id }">
      <input
        :id
        v-model="value"
        :type="showPassword ? 'text' : 'password'"
        :name
        :form
        :disabled
        v-bind="field.attrs"
      />
    </template>

    <template #button>
      <div
        class="relative flex shrink-0 grow items-end pb-2.5 pr-3 text-lavender-400"
      >
        <PhEye v-if="showPassword" weight="duotone" size="1.25em" />
        <PhEyeClosed v-else weight="duotone" size="1.25em" />
        <input
          v-model="showPassword"
          type="checkbox"
          class="absolute h-full w-full opacity-0"
        />
      </div>
    </template>

    <template v-if="errorMessage" #error>{{ errorMessage }}</template>
  </TextField>
</template>
